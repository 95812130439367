//clase global para validar solo numeros
$(function(){

    $('.validarNumericos').keypress(function(e) {
      if(isNaN(this.value + String.fromCharCode(e.charCode))) 
       return false;
    })
    .on("cut copy paste",function(e){
      e.preventDefault();
    });
  
});

//clase global para validar solo textos
$(function(){

    $('.validarTextos').keypress(function(e) {
      if(!isNaN(this.value + String.fromCharCode(e.charCode))) 
       return false;
    })
    .on("cut copy paste",function(e){
      e.preventDefault();
    });
  
})